// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS
export const createInvestmentForm = [
  {
    name: "Capital providers",
    markdown: `
In Tower Hunt, investments fill in missing "links" in the {% inlineRouterLink articleId="the-role-of-investments" sectionName="Provenance" %}"chain of custody"{% /inlineRouterLink %} of an asset. Each new "link" transfers ownership. Because of this, you don't add "seller" or "borrower" companies when creating investments &mdash; they are inherited from other "links" in the chain. The "companies" field that you see is for the companies that are providing capital &mdash; "buyer" and "lender" companies.`,
  },
  {
    name: "Investment status",
    markdown: `
Investments progress through a series of milestones on their way toward a successful closing. Tower Hunt offers a flexible approach to tracking status that allows for dates but doesn't require them.`,
  },
  {
    name: "Valuation",
    markdown: `
A key outcome of an investment is price discovery. There are different types of valuations, each with its own context.`,
  },
];

export const createAnInvestment = {
  order: 4,
  name: "Create an investment",
  keywords: "deal comp sale refinance loan own buy sell value price",
  subcategory: "Investments",
  markdown: `# Create an investment

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% inlineAppIcon iconName="capitalStack" /%}Available in {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %}.
  {% /tablessContainer %}

  {% inlineRouterLink articleId="the-role-of-investments" %}Investments{% /inlineRouterLink %} create important connections between {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %}, companies, and contacts. They also provide context about the value of properties over time.

  {% callout type="tip" marginStyles="mb-4" %}
  **New to Tower Hunt?** Take a moment to learn about {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %} and the {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %}.
  {% /callout %}

  ## What to expect

  Nearly all investment {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} are {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} by default. Creating a new investment requires **at least one** of the following:

  - A {% inlineRouterLink articleId="create-an-investment" sectionName="Capital providers" %}company providing capital{% /inlineRouterLink %}.
  - An {% inlineRouterLink articleId="create-an-investment" sectionName="Investment status" %}investment status{% /inlineRouterLink %}.

  ## Getting started

  There are three primary ways to begin creating an investment. The correct entry point for you depends on the state of the {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %} as of the date you are {% inlineRouterLink articleId="time-travel" %}time travelled{% /inlineRouterLink %} to.

  ### Add an owner

  If you are viewing an asset as of a date when Tower Hunt doesn't know the ownership, click {% inlineAppIcon iconName="pencil" /%}**Add owner** to begin creating an investment:

  ![Add owner screenshot](https://assets.towerhunt.com/site/capitalStackAddOwner.png)

  ### Trigger an investment

  If the capital stack contains layers as of the date you are viewing, click any {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** to reveal context-aware investments that can be triggered.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/triggerInvestment0.mp4" marginStyles="my-4" /%}

  {% callout type="tip" %}
  **Tip:** Looking for the different types of investments in Tower Hunt, as well as how they appear in the capital stack? We put together an {% inlineRouterLink articleId="investment-types" %}illustrated list of investment types{% /inlineRouterLink %}.
  {% /callout %}

  ### Add a debt layer

  Click the **+ Loan** button that appears between layers to begin adding a new loan.

  ![Add loan button screenshot](https://assets.towerhunt.com/site/capitalStackAddDebtLayer.png)

  ## How it works

  To create an investment, you fill in a form that includes fields for capital providers, investment status, and valuation. Then, press **Save**.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/createInvestment0.mp4" marginStyles="my-4" /%}

  {% tabbedContainer tabs=$createInvestmentForm marginStyles="mb-4" /%}

  {% callout type="tip" %}
  **Tip:** To cancel the creation process, click the {% inlineAppIcon iconName="xMark" /%}**Close button** at the top-right of the form.
  {% /callout %}

  ## Capital providers

  As the name suggests, capital providers are the companies that bring capital to the transaction. In a sale, these are the buyers. In a financing, these are the lenders.

  {% callout type="caution" %}
  **Important: If any of the listed companies did not join the capital stack at the same time, they should be modeled as separate investments** (most likely an initial investment followed by one or more {% inlineRouterLink articleId="investment-types" sectionName="Partial interest sale" %}partial interest sales{% /inlineRouterLink %}).
  {% /callout %}

  To add companies:

  {% tablessContainer marginStyles="mb-4" %}
  1. Begin typing a company name.
  2. Select an option from the list using your mouse or {% inlineKeyboardShortcut %}&uarr;{% /inlineKeyboardShortcut %}, {% inlineKeyboardShortcut %}&darr;{% /inlineKeyboardShortcut %}, and {% inlineKeyboardShortcut %}Enter{% /inlineKeyboardShortcut %}.
  3. If no options appear in the list, finish typing the company's name and press {% inlineKeyboardShortcut %}Enter{% /inlineKeyboardShortcut %} or {% inlineKeyboardShortcut %}Tab{% /inlineKeyboardShortcut %}.
  4. The added company appears against a yellow background with a {% inlineAppIcon iconName="xMark" /%}**Close button** to its right.
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip:** You can add multiple companies by repeating the steps above.
  {% /callout %}

  To remove companies:

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the {% inlineAppIcon iconName="xMark" /%}**Close button** to the right of a company's name.
  2. **Optionally**, pressing {% inlineKeyboardShortcut %}Backspace{% /inlineKeyboardShortcut %} removes the last company in the list.
  ![Remove company screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Investment status

  There are three primary investment statuses: available, under contract, and closed. Use the dropdown to designate a status (and optionally, a date) as follows:

  | **Option** | **Status** | **Date** |
  |---|---|---|
  | Available | Available | None |
  | Under Contract | Under Contract | None |
  | Closed this week | Closed | 1 day ago |
  | Closed last week | Closed | 10 days ago |
  | Closed date... | Closed | Type a new date or type {% inlineKeyboardShortcut %}Space{% /inlineKeyboardShortcut %} to open a date picker. |
  | Closed date unknown | Closed | None |
  | Unknown | None | None |

  {% callout type="caution" marginStyles="mb-4" %}
  **Heads up:** If you select *Available* or *Under Contract*, the creation process will move to the {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %}, where you can see the investment in side-by-side "before" and "after" {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stacks{% /inlineRouterLink %}.
  {% /callout %}

  {% callout type="tip" %}
  **Tip:** To clear the status field, choose "Unknown" from the dropdown.
  {% /callout %}

  ## Valuation

  This is a dollar amount that has an {% inlineRouterLink articleId="investment-types" %}investment-type-specific{% /inlineRouterLink %} meaning as follows:

  | **Investment type** | **Valuation concept** |
  |---|---|
  | Outright sale, loan sale | Purchase price |
  | Partial interest sale | Total asset value (i.e., the value of 100% ownership) |
  | Loan origination, refinancing | {% inlineRouterLink articleId="tower-hunt-glossary" sectionName="F" %}Fully-funded loan amount{% /inlineRouterLink %} |

  Type a numerical amount into the field, then choose a valuation type as follows:

  | **Option** | **What it means** |
  |---|---|
  | Final | The actual amount at closing |
  | Guidance | The amount suggested/desired by a broker, seller, or borrower |
  | Internal | The amount **you** (or your company) believes is correct |`,
};
